import fetchWrapper from "../../../../utils/fetch/fetchWrapper";
export const loadInsuranceData = async (vehicle_handle, userPrefs, setInsuranceData, setIsLoading) => {
       const params = {
         postcode: userPrefs.get("zipcode"),
       };
       let url = new URL(
         `${process.env.REACT_APP_API_HOST}/vehicles/${vehicle_handle}/insurance`
       );
       let searchParams = new URLSearchParams(params);
       url.search = searchParams;
       try {
         const insuranceData = await fetchWrapper(url.toString(), {
           method: "GET",
         });

         setInsuranceData(insuranceData.yearly_insurances);
         setIsLoading(false);
       } catch (error) {
         console.log("Failed to load insurance data.");
         console.log(error);
         setIsLoading(false);
       }
};