import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import "./Homepage.scss";
import HomepageBigPromise from "./components/HomepageBigPromise";
import getLocalVehicles from "../../../functions/vehicle/getLocalVehicles";
import ShuffleFlatArray from "../../../utils/Helpers/ShuffleFlatArray";
import VehicleCarousel from "../VehicleCarousel/VehicleCarousel"
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { vanityUrls } from "../../data/vanityUrls/vanityUrls"
import MapPage from "../../pages/MapPage/MapPage";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";


const Homepage = ({
  homePageBannerImage,
  electricVehicles,
  incentives,
  userLocation,
  dealerLocations,
  usedElectricVehicles,
  history,
  tabId,
  ip,
  uuid,
  ...props
}) => {
  const userPrefs = useContext(UserPrefsContext)

  const [vehicles, setVehicles] = useState([]);
  const setElectricVehicles = () => {
    if (electricVehicles) setVehicles(ShuffleFlatArray(electricVehicles));
  }
  const url = props.location ? (props.location.pathname + props.location.search).toLowerCase() : null

  useEffect(() => {
    document.title = process.env.REACT_APP_PAGES_HOME_TITLE;
    setElectricVehicles();
  });

  useEffect(() => {
    if (vanityUrls[url]) {
      props.history.push(vanityUrls[url])
    }
  }, [props, url])


  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === "production") {
      window.gtag("event", "conversion", {
        category: "conversion",
        send_to: "DC-9208024/ev_pa0/ev_ho0+unique", // Conversion ID e Label
        allow_custom_scripts: true,
      });
    }
  }, []);
    
  const renderDetails =
    electricVehicles && incentives ? (
      <>
        <VehicleCarousel
          electricVehicles={getLocalVehicles(vehicles)}
          userLocation={userLocation}
        />
      </>
    ) : (
      <section className="container-fluid grey">
        <LoadingSpinner />
      </section>
    );

  return (
    <>
      <HomepageBigPromise
        userLocation={userLocation}
        homePageBannerImage={homePageBannerImage}
      />
      {renderDetails}
      <MapPage
        userLocation={userLocation}
        tabId={tabId}
        zipcode={userPrefs.get("zipcode")}
        electricVehicles={electricVehicles}
        dealerLocations={dealerLocations}
        homePage={true}
      />
    </>
  );
};

export default Homepage;

Homepage.propTypes = {
  homePageBannerImage: PropTypes.string,
  electricVehicles: PropTypes.array,
  incentives: PropTypes.array,
  ip: PropTypes.string,
  uuid: PropTypes.string
};
